import * as React from 'react';
import { TASK_DATE_QUERY, UserWeight } from '@lean-body/src/domain';
import { isiOSApp, useQuery } from '@lean-body/src/util';
import { routes } from '@lean-body/src/route';
import { LifetimeActivityChart, LifetimeActivityType, ActivityTabs } from '../organisms/lifetime_activity_chart';
import dayjs from 'dayjs';
import { fetchDataForWeight } from '../organisms/weight_task';
import { fetchDataForMealCalories, fetchDataForSteps, fetchDataForWorkout } from '../organisms/lifetime_task_activity';
import { useNavigate } from 'react-router';
var TaskNameToLifetimeActivityType = {
    workout: 'workoutCalorie',
    steps: 'steps',
    weight: 'weight',
};
export var ChartTasks = function (_a) {
    var taskName = _a.taskName, chartType = _a.chartType, apiClient = _a.apiClient;
    var today = new Date();
    var query = useQuery();
    var navigate = useNavigate();
    var queryDate = dayjs(query.get(TASK_DATE_QUERY) || today).toDate();
    var date = React.useState(queryDate)[0];
    var _b = React.useState([]), weightActivities = _b[0], setWeightActivities = _b[1];
    var _c = React.useState(null), goalByDate = _c[0], setGoalByDate = _c[1];
    var _d = React.useState([]), workoutCaloriesList = _d[0], setWorkoutCaloriesList = _d[1];
    var _e = React.useState([]), stepActivities = _e[0], setStepActivities = _e[1];
    var _f = React.useState([]), mealCalories = _f[0], setMealCalories = _f[1];
    var _g = React.useState([]), graphScales = _g[0], setGraphScales = _g[1];
    var tab = ActivityTabs[chartType];
    var activityType = LifetimeActivityType[TaskNameToLifetimeActivityType[taskName]];
    React.useEffect(function () {
        var isMounted = true;
        if (!isiOSApp()) {
            navigate(routes.TOP.compile());
        }
        else {
            switch (activityType) {
                case LifetimeActivityType.steps:
                    fetchDataForSteps(tab, apiClient, date).then(function (_a) {
                        var taskGoal = _a[0], stepActivities = _a[2];
                        if (isMounted) {
                            setGoalByDate(taskGoal);
                            setStepActivities(stepActivities.stepActivityList);
                            setGraphScales(stepActivities.graphScales);
                        }
                    });
                    break;
                case LifetimeActivityType.workoutCalorie:
                    fetchDataForWorkout(tab, apiClient, date).then(function (_a) {
                        var goal = _a[0], workoutCalories = _a[2];
                        if (isMounted) {
                            setGoalByDate(goal);
                            setWorkoutCaloriesList(workoutCalories.workoutCalorieList);
                            setGraphScales(workoutCalories.graphScales);
                        }
                    });
                    break;
                case LifetimeActivityType.weight:
                    fetchDataForWeight(tab, apiClient, date).then(function (_a) {
                        var goal = _a[0], weightActivities = _a[2];
                        if (isMounted) {
                            setGoalByDate(goal ? Number(UserWeight.formatWeight(goal.toString())) : 0);
                            setWeightActivities(weightActivities.weightActivityList);
                            setGraphScales(weightActivities.graphScales);
                        }
                    });
                    break;
                case LifetimeActivityType.mealCalorie:
                    fetchDataForMealCalories(tab, apiClient, date).then(function (_a) {
                        var goal = _a[0], v = _a[1];
                        if (isMounted) {
                            setGoalByDate(goal);
                            setMealCalories(v.mealCalories);
                            setGraphScales(v.graphScales);
                        }
                    });
                    break;
            }
        }
        return function () {
            isMounted = false;
        };
    }, []);
    return (React.createElement(LifetimeActivityChart, { tab: tab, target: activityType, stepActivities: stepActivities, workoutCaloriesList: workoutCaloriesList, weightActivities: weightActivities, mealCalories: mealCalories, rightmostDate: date, horizontalLineValue: goalByDate, graphScales: graphScales }));
};
