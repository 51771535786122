import * as React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Colors, MediaBreakPointUp } from '../styles';
import { CloseRoundIcon } from '../atoms/icons/close_round_icon';
export var RecordMealImageDetail = function (_a) {
    var _b;
    var index = _a.index, fileMap = _a.fileMap, onClickImage = _a.onClickImage, onClickDeleteImage = _a.onClickDeleteImage;
    var styles = StyleSheet.create({
        imageContainer: (_b = {
                position: 'relative',
                width: 'calc((100vw - 48px - 12px) / 3)',
                height: 'calc((100vw - 48px - 12px) / 3)',
                borderRadius: 8,
                cursor: 'pointer',
                backgroundColor: Colors.BackgroundGrayD
            },
            _b[MediaBreakPointUp.MD] = {
                width: 112,
                height: 112,
            },
            _b),
        imageBox: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
            borderRadius: 8,
            overflow: 'hidden',
        },
        image: {
            backgroundImage: "url(".concat(fileMap.url, ")"),
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            width: '100%',
            height: '100%',
        },
        closeIcon: {
            position: 'absolute',
            top: -6,
            right: -4,
            width: 18,
            height: 18,
        },
    });
    return (React.createElement("div", { className: css(styles.imageContainer) },
        React.createElement("div", { className: css(styles.imageBox), onClick: function () { return onClickImage(index); } },
            React.createElement("div", { className: css(styles.image) })),
        onClickDeleteImage && (React.createElement("div", { className: css(styles.closeIcon) },
            React.createElement(CloseRoundIcon, { color: Colors.GrayDarkest, size: 18, onClick: function (e) {
                    e.stopPropagation();
                    onClickDeleteImage(index);
                } })))));
};
