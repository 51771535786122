import * as React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Button, ButtonType } from '../atoms';
import { PlusIcon } from '../atoms/icons/plus_icon';
import { Colors } from '../styles';
import { RecordMealImageList } from './record_meal_image_list';
export var MealDetail = function (_a) {
    var _b;
    var title = _a.title, meal = _a.meal, onClick = _a.onClick;
    var _c = React.useState(new Map()), imageMap = _c[0], setImageMap = _c[1];
    React.useEffect(function () {
        var tempImageMap = new Map();
        var files = meal === null || meal === void 0 ? void 0 : meal.userMealImages.map(function (image, i) { return image.fileMap(i); });
        if (files === null || files === void 0 ? void 0 : files.length) {
            Promise.all(files).then(function (fileList) {
                fileList.forEach(function (v) {
                    tempImageMap.set(v.index, { url: v.url, file: v.file });
                });
                setImageMap(tempImageMap);
            });
        }
        else {
            setImageMap(new Map());
        }
    }, [meal]);
    return (React.createElement("div", { className: css(styles.container) },
        React.createElement("div", { className: css(styles.header) },
            title,
            meal ? (React.createElement(React.Fragment, null,
                React.createElement("div", { className: css(styles.totalCalorie) },
                    meal.sumCalories,
                    "kcal"),
                React.createElement(Button, { className: css(styles.editButton), buttonType: ButtonType.borderGray, onClick: onClick }, "\u7DE8\u96C6"))) : (React.createElement(Button, { className: css(styles.addButton), buttonType: ButtonType.primary, onClick: onClick },
                React.createElement(PlusIcon, { classObject: styles.plusIcon, color: Colors.White }),
                React.createElement("div", { className: css(styles.addButtonText) }, "\u8A18\u9332\u3059\u308B")))),
        React.createElement("div", { className: css(styles.content) }, (meal === null || meal === void 0 ? void 0 : meal.isNotEating) ? (React.createElement("div", { className: css(styles.notEatingText) }, "\u98DF\u3079\u306A\u304B\u3063\u305F")) : (React.createElement(React.Fragment, null, (_b = meal === null || meal === void 0 ? void 0 : meal.userMealMenus) === null || _b === void 0 ? void 0 :
            _b.map(function (v, i) { return (React.createElement("div", { className: css(styles.menuRow), key: i },
                React.createElement("div", { className: css(styles.menuName) }, v.menuName),
                React.createElement("div", { className: css(styles.calorie) },
                    v.caloriesForAmount(v.amountPercentage),
                    "kcal"))); }),
            imageMap.size > 0 && (React.createElement("div", { className: css(styles.menuImageContainer) },
                React.createElement(RecordMealImageList, { imageMap: imageMap }))))))));
};
var styles = StyleSheet.create({
    container: {},
    header: {
        color: Colors.TextDark,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: 16,
        fontWeight: 'bold',
        lineHeight: '160%',
        letterSpacing: 1.28,
        gap: 10,
        height: 26,
        marginTop: 24,
        marginBottom: 8,
    },
    totalCalorie: {
        textAlign: 'right',
        flexGrow: 1,
    },
    addButton: {
        display: 'flex',
        width: 84,
        height: 30,
        gap: 2,
        padding: '7px 8px 8px 6px',
        borderRadius: 8,
    },
    editButton: {
        color: Colors.Black,
        fontSize: 12,
        fontWeight: 'bold',
        lineHeight: '100%',
        width: 50,
        height: 29,
        padding: '8px 10px 9px 10px',
        borderRadius: 8,
    },
    plusIcon: {
        width: 13,
        height: 13,
    },
    addButtonText: {
        fontSize: 12,
        fontWeight: 'bold',
        lineHeight: '100%',
        marginTop: 1,
    },
    content: {
        marginTop: 12,
    },
    notEatingText: {
        fontSize: 14,
        fontWeight: 500,
        lineHeight: '140%',
        letterSpacing: 0.56,
        paddingTop: 12,
        paddingBottom: 12,
        borderTop: "1px solid ".concat(Colors.BorderLightGray),
    },
    menuRow: {
        display: 'flex',
        flexDirection: 'column',
        fontSize: 12,
        lineHeight: '160%',
        letterSpacing: 0.48,
        gap: 4,
        paddingTop: 12,
        paddingBottom: 12,
        borderTop: "1px solid ".concat(Colors.BorderLightGray),
    },
    menuName: {
        color: Colors.Black,
        fontWeight: 'bold',
    },
    calorie: {
        color: Colors.GrayDarkest,
        fontWeight: 500,
    },
    menuImageContainer: {
        marginTop: 12,
    },
});
