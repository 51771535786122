var WeightActivities = /** @class */ (function () {
    function WeightActivities(data) {
        Object.defineProperty(this, "weightActivityList", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "graphScales", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.weightActivityList = data.weightActivityList.map(function (v) { return new WeightActivity(v); });
        this.graphScales = data.graphScales;
    }
    return WeightActivities;
}());
export { WeightActivities };
var WeightActivity = /** @class */ (function () {
    function WeightActivity(data) {
        Object.defineProperty(this, "userID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "weight", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "dateAt", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.userID = data.userID;
        this.weight = data.weight;
        this.dateAt = data.dateAt;
    }
    return WeightActivity;
}());
export { WeightActivity };
