var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Chart, registerables, } from 'chart.js';
import { Line } from 'react-chartjs-2';
import * as dayjs from 'dayjs';
import { Colors } from '../styles';
import { LoadingIndicator } from '../atoms';
import { useIsMounted } from '@lean-body/src/util';
import { HorizonalLinePlugin } from '@lean-body/src/util/chart_js_plugin';
Chart.register.apply(Chart, registerables);
Chart.register(HorizonalLinePlugin());
export var CHART_LENGTH_FOR_WEEKLY = 7;
export var CHART_LENGTH_FOR_MONTHLY = 30;
export var CHART_LENGTH_FOR_QUARTERLY = 90;
export var CHART_PAGE_START = 1;
var LABEL_WORKOUT_CALORIE = '消費カロリー:kcal';
var LABEL_STEP = '歩数:歩';
var LABEL_MEAL_CALORIE = '摂取カロリー:kcal';
var LABEL_WEIGHT = '体重:kg';
var WEEKLY_MAX_TICKS_LIMIT = 7;
var MONTHLY_MAX_TICKS_LIMIT = 4;
var THREE_MONTH_MAX_TICKS_LIMIT = 3;
var PADDING_FOR_HORIZONTAL_LINE = 30;
export var ActivityTabs = {
    daily: '日',
    weekly: '週',
    monthly: '月',
    quarterly: '3ヶ月',
};
export var LifetimeActivityTabs = {
    weekly: '週',
    monthly: '月',
    threeMonth: '3ヶ月',
};
export var LifetimeActivityType = {
    workoutCalorie: 'workout_calorie',
    steps: 'steps',
    weight: 'weight',
    mealCalorie: 'meal_calorie',
};
export var LifetimeActivityUnit = {
    workoutCalorie: 'kcal',
    steps: '歩',
    weight: 'kg',
    mealCalorie: 'kcal',
};
export var ChartSwipe = {
    right: 'right',
    left: 'left',
};
export var LifetimeActivityChart = function (props) {
    var workoutCaloriesList = props.workoutCaloriesList, weightActivities = props.weightActivities, stepActivities = props.stepActivities, mealCalories = props.mealCalories, target = props.target, tab = props.tab, rightmostDate = props.rightmostDate, horizontalLineValue = props.horizontalLineValue, graphScales = props.graphScales, classObject = props.classObject;
    var isMounted = useIsMounted();
    var today = dayjs().startOf('day');
    var vm = new LifeTimeActivityChartVM(workoutCaloriesList, weightActivities, stepActivities, mealCalories, today);
    // x軸の個数(chartLength)
    var chartLength = vm.tabToChartLength(tab);
    // レッスン時間の棒グラフのデザイン調整
    var barBorderRadius = tab === ActivityTabs.weekly ? 6 : 2;
    // 体重の折れ線グラフのデザイン調整
    var pointRadius = vm.tabToPointRadius(tab, chartLength);
    // 描画するまでロードを表示するための変数
    var _a = React.useState(false), showable = _a[0], setShowable = _a[1];
    // x軸のデータをmap
    var labels = vm.buildXLabels(chartLength, tab, dayjs(rightmostDate).startOf('day'));
    // y軸のデータをmap
    var stepValues = vm.buildSteps(chartLength);
    var weightValues = vm.buildWeightValues(chartLength, tab);
    var workoutCalorieValues = vm.buildWorkoutCalorieValues(chartLength);
    var mealCalorieValues = vm.buildMealCalorieValues(chartLength);
    var datasets = function () {
        if (target === LifetimeActivityType.workoutCalorie) {
            return [
                {
                    label: LABEL_WORKOUT_CALORIE,
                    type: 'bar',
                    data: workoutCalorieValues,
                    backgroundColor: Colors.PrimaryDark,
                    yAxisID: 'y',
                },
            ];
        }
        if (target === LifetimeActivityType.steps) {
            return [
                {
                    label: LABEL_STEP,
                    type: 'bar',
                    data: stepValues,
                    backgroundColor: Colors.PrimaryDark,
                    yAxisID: 'y',
                },
            ];
        }
        if (target === LifetimeActivityType.mealCalorie) {
            return [
                {
                    label: LABEL_MEAL_CALORIE,
                    type: 'bar',
                    data: mealCalorieValues,
                    backgroundColor: Colors.PrimaryDark,
                    yAxisID: 'y',
                },
            ];
        }
        if (target === LifetimeActivityType.weight) {
            return [
                {
                    label: LABEL_WEIGHT,
                    type: 'line',
                    data: weightValues,
                    borderColor: Colors.Gray,
                    borderWidth: 1.5,
                    backgroundColor: Colors.PrimaryDark,
                    pointRadius: pointRadius,
                    pointBorderColor: Colors.StrictBlack,
                    pointBorderWidth: 1,
                    segment: {
                        // 折れ線グラフで未入力の日を点線でskipしてるように見せるデザイン調整
                        borderColor: function (ctx) { return vm.chartValueIsSkipped(ctx, Colors.Gray); },
                        borderDash: function (ctx) { return vm.chartValueIsSkipped(ctx, [5, 4]); },
                    },
                    yAxisID: 'y',
                },
            ];
        }
        return [];
    };
    var scales = function () {
        var yMin = graphScales && target === LifetimeActivityType.weight ? graphScales[0] : 0;
        var yMax = graphScales ? graphScales[graphScales.length - 1] : 0;
        var step = graphScales
            ? target === LifetimeActivityType.weight
                ? (yMax - yMin) / (graphScales.length - 1)
                : yMax / (graphScales.length - 1)
            : 0;
        if (target === LifetimeActivityType.workoutCalorie ||
            target === LifetimeActivityType.steps ||
            target === LifetimeActivityType.weight ||
            target === LifetimeActivityType.mealCalorie) {
            return {
                x: {
                    grid: {
                        display: false, // x軸の縦線を非表示にするオプション
                    },
                    ticks: __assign(__assign({}, scaleFont), { minRotation: 0, maxRotation: 0, autoSkip: vm.isAutoSkip(tab), maxTicksLimit: vm.maxTicksLimit(tab) }),
                },
                y: {
                    display: true,
                    position: 'left',
                    max: yMax,
                    min: yMin,
                    ticks: __assign(__assign({}, scaleFont), { stepSize: step, callback: function (label) {
                            return target === LifetimeActivityType.weight ? label : Math.floor(label);
                        } }),
                    grid: {
                        drawTicks: false, // 目盛線を非表示にする
                        color: Colors.BackgroundGrayC,
                    },
                    border: {
                        display: false, // 目盛の軸線を非表示にする
                    },
                },
            };
        }
        return {};
    };
    // グラフと凡例の間に余白を追加する
    var customPlugin = {
        id: 'addPadding',
        beforeInit: function (chart) {
            var originalFit = chart.legend.fit;
            chart.legend.fit = function fit() {
                originalFit.bind(chart.legend)();
                this.height += 12;
            };
        },
    };
    return (React.createElement("div", { className: css(styles.container, showable && styles.showable, classObject) },
        React.createElement(Line, { data: {
                labels: labels,
                datasets: datasets(),
            }, options: {
                aspectRatio: 1.8, // canvasの縦横の比率
                elements: {
                    bar: {
                        borderRadius: barBorderRadius, // 棒グラフの角丸
                    },
                    line: {
                        spanGaps: true, // y軸で未入力の場所があってもskipしないために必要なオプション
                    },
                },
                scales: scales(),
                plugins: {
                    legend: {
                        // 凡例のオプション
                        reverse: true,
                        align: 'start',
                        labels: {
                            usePointStyle: true, // generateLabels()で pointStyle を使うのに必要なオプション
                            boxWidth: 7,
                            boxHeight: 8,
                            color: Colors.Black,
                            generateLabels: vm.generateLabels,
                        },
                        onClick: null, // nullを明示的に設定しないと、クリックが何かを発火しようとしてnullエラーになるので設定
                    },
                    tooltip: {
                        callbacks: {
                            label: vm.tooltipLabel,
                        },
                    },
                    horizontalLine: {
                        yAxisValue: horizontalLineValue,
                    },
                },
                animation: {
                    onComplete: function () {
                        if (isMounted) {
                            setShowable(true);
                        }
                    },
                },
                layout: {
                    padding: {
                        right: !!horizontalLineValue && PADDING_FOR_HORIZONTAL_LINE,
                    },
                },
            }, plugins: [customPlugin] }),
        !showable && React.createElement(LoadingIndicator, { className: css(styles.loading), size: 40 })));
};
var LifeTimeActivityChartVM = /** @class */ (function () {
    function LifeTimeActivityChartVM(workoutCaloriesList, weightActivities, stepActivities, mealCalories, today) {
        Object.defineProperty(this, "today", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "lessonMinutes", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "weights", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "steps", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "workoutCalories", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "mealCalories", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "YLabelSize", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 5
        }); // y軸のメモリの数を5に固定して、その間隔は計算する
        this.today = today;
        this.weights = weightActivities.map(function (v) { return v.weight; });
        this.steps = stepActivities.map(function (v) { return v.steps; });
        this.workoutCalories = workoutCaloriesList.map(function (v) { return v.calories; });
        this.mealCalories = mealCalories.map(function (v) { return v.calories; });
    }
    Object.defineProperty(LifeTimeActivityChartVM.prototype, "buildLessonMinuteValues", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (chartLength) {
            return this.lessonMinutes.slice(0, chartLength).reverse();
        }
    });
    Object.defineProperty(LifeTimeActivityChartVM.prototype, "buildSteps", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (chartLength) {
            return this.steps.slice(0, chartLength).reverse();
        }
    });
    Object.defineProperty(LifeTimeActivityChartVM.prototype, "buildWorkoutCalorieValues", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (chartLength) {
            return this.workoutCalories.slice(0, chartLength).reverse();
        }
    });
    Object.defineProperty(LifeTimeActivityChartVM.prototype, "buildMealCalorieValues", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (chartLength) {
            return this.mealCalories.slice(0, chartLength).reverse();
        }
    });
    Object.defineProperty(LifeTimeActivityChartVM.prototype, "buildWeightValues", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (chartLength, tab) {
            var targets = this.weights.slice(0, chartLength);
            var lastWeight = targets.find(function (v) { return v; });
            var firstWeight = __spreadArray([], targets, true).reverse().find(function (v) { return v; });
            return targets.reverse().map(function (v, i) {
                if (!v) {
                    // グラフの最後の体重が入力されていない場合は、最後に入力した体重を反映させる
                    if (i === chartLength - 1) {
                        return lastWeight || NaN;
                    }
                    // グラフの最初の体重が入力されていない場合は、最初に入力した体重を反映させる(週のみ)
                    if (i === 0 && tab === ActivityTabs.weekly) {
                        return firstWeight || NaN;
                    }
                }
                return v || NaN;
            });
        }
    });
    Object.defineProperty(LifeTimeActivityChartVM.prototype, "tabToChartLength", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (v) {
            if (v === ActivityTabs.weekly) {
                return CHART_LENGTH_FOR_WEEKLY;
            }
            else if (v === ActivityTabs.monthly) {
                return CHART_LENGTH_FOR_MONTHLY;
            }
            else {
                return CHART_LENGTH_FOR_QUARTERLY;
            }
        }
    });
    Object.defineProperty(LifeTimeActivityChartVM.prototype, "tabToPointRadius", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (tab, chartLength) {
            var result;
            if (tab === ActivityTabs.weekly) {
                result = new Array(chartLength).fill(4.5);
                var firstWeight = this.weights.slice(0, chartLength).reverse()[0];
                // 先頭の体重が入力されていない場合は、0にして点を表示しない
                if (!firstWeight) {
                    result[0] = 0;
                }
            }
            else {
                result = new Array(chartLength - 1).fill(0);
                if (tab === ActivityTabs.monthly) {
                    result.push(3);
                }
                else {
                    result.push(2);
                }
            }
            return result;
        }
    });
    Object.defineProperty(LifeTimeActivityChartVM.prototype, "buildXLabels", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (chartLength, tab, day) {
            return __spreadArray([], Array(chartLength), true).map(function (_, i) {
                var targetTime = day.add(-i, 'day');
                if (tab === ActivityTabs.quarterly) {
                    // 3ヶ月表示の時は左端とx月1日の時にラベルを表示する
                    // 左端は15日より前のみ表示する（15日以降は翌月1日のラベルと被る可能性があるので）
                    if (targetTime.date() === 1 || (i === chartLength - 1 && targetTime.date() <= 15)) {
                        return "".concat(targetTime.year(), ".").concat(targetTime.month() + 1);
                    }
                    else {
                        return '';
                    }
                }
                else {
                    return targetTime.format('M/D');
                }
            })
                .reverse();
        }
    });
    Object.defineProperty(LifeTimeActivityChartVM.prototype, "chartValueIsSkipped", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (ctx, value) {
            return ctx.p0.skip || ctx.p1.skip ? value : undefined;
        }
    });
    // 凡例のデザインのパラメータ
    Object.defineProperty(LifeTimeActivityChartVM.prototype, "generateLabels", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (chart) {
            return chart.data.datasets.map(function (dataset) {
                return {
                    text: dataset.label,
                    // 凡例の文字色
                    fontColor: Colors.TextDark,
                    // 凡例のboxの塗りつぶし
                    fillStyle: dataset.backgroundColor,
                    // 凡例のboxのborder(周囲)の太さ
                    lineWidth: dataset.type === 'bar' ? 3 : dataset.pointBorderWidth,
                    // 凡例のboxのborder(周囲)の塗りつぶし色
                    strokeStyle: dataset.type === 'bar' ? dataset.backgroundColor : dataset.pointBorderColor,
                    // 凡例のboxの形, rectRounded=角丸四角, circle=丸
                    pointStyle: dataset.type === 'bar' ? 'rectRounded' : 'circle',
                };
            });
        }
    });
    Object.defineProperty(LifeTimeActivityChartVM.prototype, "isAutoSkip", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (tab) {
            return tab === ActivityTabs.monthly;
        }
    });
    Object.defineProperty(LifeTimeActivityChartVM.prototype, "workoutCaloriesMaxCeil", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (workoutCaloriesMax) {
            // 最大値より大きい1番近い15単位の数値を返す
            return Math.ceil(workoutCaloriesMax / 15) * 15;
        }
    });
    Object.defineProperty(LifeTimeActivityChartVM.prototype, "stepMaxCeil", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (stepMax) {
            // 最大値より大きい1番近い千単位の数値を返す
            return Math.ceil(stepMax / 1000) * 1000;
        }
    });
    Object.defineProperty(LifeTimeActivityChartVM.prototype, "mealCaloriesMaxCeil", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (mealCaloriesMax) {
            // 最大値より大きい1番近い百単位の数値を返す
            return Math.ceil(mealCaloriesMax / 100) * 100;
        }
    });
    Object.defineProperty(LifeTimeActivityChartVM.prototype, "maxTicksLimit", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (tab) {
            switch (tab) {
                case ActivityTabs.weekly:
                    return WEEKLY_MAX_TICKS_LIMIT;
                case ActivityTabs.monthly:
                    return MONTHLY_MAX_TICKS_LIMIT;
                case ActivityTabs.quarterly:
                    return THREE_MONTH_MAX_TICKS_LIMIT;
            }
        }
    });
    Object.defineProperty(LifeTimeActivityChartVM.prototype, "tooltipLabel", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (data) {
            var unit;
            switch (data.dataset.label) {
                case LABEL_WORKOUT_CALORIE:
                    unit = LifetimeActivityUnit.workoutCalorie;
                    break;
                case LABEL_STEP:
                    unit = LifetimeActivityUnit.steps;
                    break;
                case LABEL_MEAL_CALORIE:
                    unit = LifetimeActivityUnit.mealCalorie;
                    break;
                case LABEL_WEIGHT:
                    unit = LifetimeActivityUnit.weight;
                    break;
            }
            return "".concat(data.formattedValue, " ").concat(unit);
        }
    });
    return LifeTimeActivityChartVM;
}());
var styles = StyleSheet.create({
    container: {
        position: 'relative',
        visibility: 'hidden',
    },
    showable: {
        visibility: 'visible',
    },
    loading: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        visibility: 'visible',
    },
});
var scaleFont = {
    // x軸,y軸の文字のcss
    color: Colors.GrayDarkest,
    font: {
        size: 11,
        weight: '500',
    },
};
