import { Nutrient } from './nutrient';
export var MEAL_SCORE = {
    basic: { key: 'basic', title: '基本スコア', isStar: true },
    diet: { key: 'diet', title: 'ダイエット度', isStar: false },
    vitality: { key: 'vitality', title: '元気度', isStar: false },
    intestinalCleanliness: { key: 'intestinal_cleanliness', title: '腸キレイ度', isStar: false },
    skinGlossiness: { key: 'skin_glossiness', title: '肌ツヤ度', isStar: false },
};
var MealScore = /** @class */ (function () {
    function MealScore(data) {
        Object.defineProperty(this, "enteredThreeMeals", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "basic", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "diet", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "vitality", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "intestinalCleanliness", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "skinGlossiness", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.enteredThreeMeals = data.enteredThreeMeals;
        this.basic = new MealScoreDetail(data.basic, MEAL_SCORE.basic);
        this.diet = new MealScoreDetail(data.diet, MEAL_SCORE.diet);
        this.vitality = new MealScoreDetail(data.vitality, MEAL_SCORE.vitality);
        this.intestinalCleanliness = new MealScoreDetail(data.intestinalCleanliness, MEAL_SCORE.intestinalCleanliness);
        this.skinGlossiness = new MealScoreDetail(data.skinGlossiness, MEAL_SCORE.skinGlossiness);
    }
    return MealScore;
}());
export { MealScore };
var MealScoreDetail = /** @class */ (function () {
    function MealScoreDetail(data, scoreDetail) {
        Object.defineProperty(this, "key", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "title", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "score", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "advise", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isStar", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "nutrients", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.key = scoreDetail.key;
        this.title = scoreDetail.title;
        this.score = data === null || data === void 0 ? void 0 : data.score;
        this.advise = data === null || data === void 0 ? void 0 : data.advise;
        this.isStar = scoreDetail.isStar;
        this.nutrients = (data === null || data === void 0 ? void 0 : data.nutrients.map(function (v) { return new Nutrient(v); })) || [];
    }
    return MealScoreDetail;
}());
export { MealScoreDetail };
