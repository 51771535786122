import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '../styles';
import { MealScoreHighIcon } from '../atoms/icons/meal_score_high_icon';
import { MealScoreLowIcon } from '../atoms/icons/meal_score_low_icon';
import { MealScoreMiddleIcon } from '../atoms/icons/meal_score_middle_icon';
export var MealScoreBox = function (_a) {
    var scoreDetail = _a.scoreDetail, onClickDetail = _a.onClickDetail;
    var onClick = function () {
        onClickDetail(scoreDetail.key);
    };
    return (React.createElement("div", { className: css(styles.container), onClick: scoreDetail && onClick },
        (scoreDetail === null || scoreDetail === void 0 ? void 0 : scoreDetail.score) == 1 ? (React.createElement(MealScoreLowIcon, { color: Colors.Orange, classObject: styles.icon })) : (scoreDetail === null || scoreDetail === void 0 ? void 0 : scoreDetail.score) == 2 ? (React.createElement(MealScoreMiddleIcon, { color: Colors.Yellow })) : (scoreDetail === null || scoreDetail === void 0 ? void 0 : scoreDetail.score) == 3 ? (React.createElement(MealScoreHighIcon, { color: Colors.ProgressGreen })) : (React.createElement(MealScoreMiddleIcon, null)),
        React.createElement("div", { className: css(styles.scoreTitle) }, scoreDetail.title)));
};
var styles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        height: 52,
        padding: '10px 4px',
        gap: 4,
        borderRadius: 14,
        border: "1.5px solid ".concat(Colors.BorderLightGray),
        cursor: 'pointer',
    },
    icon: {
        width: 18,
        height: 18,
    },
    scoreTitle: {
        fontSize: 10,
        fontWeight: 'bold',
        lineHeight: '100%',
    },
    arrowIcon: {
        marginLeft: 4,
    },
});
