var WorkoutCalories = /** @class */ (function () {
    function WorkoutCalories(data) {
        Object.defineProperty(this, "workoutCalorieList", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "graphScales", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.workoutCalorieList = data.workoutCalorieList.map(function (v) { return new WorkoutCalorie(v); });
        this.graphScales = data.graphScales;
    }
    return WorkoutCalories;
}());
export { WorkoutCalories };
var WorkoutCalorie = /** @class */ (function () {
    function WorkoutCalorie(data) {
        Object.defineProperty(this, "calories", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "date", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.calories = data.calories;
        this.date = new Date(data.date * 1000);
    }
    return WorkoutCalorie;
}());
export { WorkoutCalorie };
