var _a;
import * as React from 'react';
import * as dayjs from 'dayjs';
import { useState, useEffect } from 'react';
import { css, StyleSheet } from 'aphrodite';
import { MealHeader } from '../atoms/meal_header';
import { getFormattedToFormat, useQuery } from '@lean-body/src/util';
import { NutrientItems } from '../organisms/nutrient_items';
import { PageLoading } from '../organisms';
import { MEAL_SCORE } from '@lean-body/src/domain/meal_score';
import { DailyTasksPageTaskName, routes } from '@lean-body/src/route';
import { useNavigate } from 'react-router';
import { MealScoreIcons } from '../molecules/meal_score_icons';
import { MEAL_EMPTY_MESSAGE } from '../organisms/meal_task';
import { MediaBreakPointUp } from '../styles';
export var MealScorePage = function (_a) {
    var apiClient = _a.apiClient;
    var navigate = useNavigate();
    var query = useQuery();
    var date = dayjs(query.get('date') || new Date()).toDate();
    var type = query.get('type') || MEAL_SCORE.basic.key;
    var _b = useState(), score = _b[0], setScore = _b[1];
    var _c = useState(), detailScore = _c[0], setDetailScore = _c[1];
    useEffect(function () {
        apiClient.fetchMealScores(date).then(function (fetchedScores) {
            setScore(fetchedScores);
            switch (type) {
                case MEAL_SCORE.basic.key:
                    return setDetailScore(fetchedScores.basic);
                case MEAL_SCORE.diet.key:
                    return setDetailScore(fetchedScores.diet);
                case MEAL_SCORE.vitality.key:
                    return setDetailScore(fetchedScores.vitality);
                case MEAL_SCORE.intestinalCleanliness.key:
                    return setDetailScore(fetchedScores.intestinalCleanliness);
                case MEAL_SCORE.skinGlossiness.key:
                    return setDetailScore(fetchedScores.skinGlossiness);
            }
        });
    }, []);
    var onClickBack = function () {
        var taskName = DailyTasksPageTaskName.meal;
        navigate("".concat(routes.DAILY_TASKS.compile({ taskName: taskName }), "?date=").concat(getFormattedToFormat(date)));
    };
    if (!score) {
        return React.createElement(PageLoading, null);
    }
    return (React.createElement("div", null,
        React.createElement(MealHeader, { onClickBack: onClickBack }),
        React.createElement("div", { className: css(styles.container) },
            React.createElement("div", { className: css(styles.sectionTitle) }, detailScore.title),
            React.createElement("div", { className: css(styles.detailScoreIcons, detailScore.isStar && styles.detailScoreStarIcons) },
                !score.enteredThreeMeals && React.createElement("div", { className: css(styles.detailScoreEmptyMessage) }, MEAL_EMPTY_MESSAGE),
                React.createElement(MealScoreIcons, { score: detailScore.score, isStar: detailScore.isStar, isDetailPage: true })),
            React.createElement("div", { className: css(styles.sectionTitle) }, "\u30A2\u30C9\u30D0\u30A4\u30B9"),
            score.enteredThreeMeals ? (React.createElement("div", { className: css(styles.adviseText) }, detailScore.advise)) : (React.createElement("div", { className: css(styles.detailAdviseEmptyMessage) }, MEAL_EMPTY_MESSAGE)),
            React.createElement("div", { className: css(styles.sectionTitle) }, "\u5224\u5B9A\u57FA\u6E96\u3068\u306A\u308B\u6804\u990A\u7D20"),
            React.createElement(NutrientItems, { nutrients: detailScore.nutrients, isEntered: score.enteredThreeMeals }))));
};
var styles = StyleSheet.create({
    container: (_a = {
            width: '100%',
            marginTop: 0,
            padding: '0 24px 60px'
        },
        _a[MediaBreakPointUp.MD] = {
            width: 600,
            margin: '0 auto',
            padding: '0 0 60px',
        },
        _a),
    sectionTitle: {
        fontSize: 16,
        fontWeight: 'bold',
        lineHeight: '160%',
        letterSpacing: 1.28,
        padding: '24px 0 8px',
    },
    detailScoreIcons: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        height: 58,
    },
    detailScoreStarIcons: {
        height: 64,
    },
    detailScoreEmptyMessage: {
        position: 'absolute',
        textAlign: 'center',
        height: 34,
        top: 14,
        fontSize: 12,
        fontWeight: 500,
        lineHeight: '140%',
        letterSpacing: 0.48,
        whiteSpace: 'pre-wrap',
    },
    detailAdviseEmptyMessage: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        height: 84,
        fontSize: 12,
        fontWeight: 500,
        lineHeight: '160%',
        letterSpacing: 0.48,
        whiteSpace: 'pre-wrap',
    },
    adviseText: {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '160%',
        letterSpacing: 0.56,
        whiteSpace: 'pre-wrap',
    },
});
