import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors } from '../styles';
import { LifetimeActivityType, LifetimeActivityUnit } from './lifetime_activity_chart';
import { FireIcon } from '../atoms';
import { StepsIcon } from '../atoms';
export var ActivityDiff = function (_a) {
    var activityType = _a.activityType, activity = _a.activity, activityValue = _a.activityValue, activityDiff = _a.activityDiff, classObject = _a.classObject;
    var _b = React.useState(null), value = _b[0], setValue = _b[1];
    var _c = React.useState(null), diff = _c[0], setDiff = _c[1];
    var _d = React.useState(null), unit = _d[0], setUnit = _d[1];
    React.useEffect(function () {
        switch (activityType) {
            case LifetimeActivityType.workoutCalorie:
                setValue(activity === null || activity === void 0 ? void 0 : activity.kcal);
                setDiff(activity === null || activity === void 0 ? void 0 : activity.kcalDiff);
                setUnit(LifetimeActivityUnit.workoutCalorie);
                break;
            case LifetimeActivityType.steps:
                setValue(activity === null || activity === void 0 ? void 0 : activity.steps);
                setDiff(activity === null || activity === void 0 ? void 0 : activity.stepsDiff);
                setUnit(LifetimeActivityUnit.steps);
                break;
            case LifetimeActivityType.mealCalorie:
                setValue(activityValue);
                setDiff(activityDiff);
                setUnit(LifetimeActivityUnit.mealCalorie);
                break;
        }
    }, [activity, activityValue, activityDiff]);
    return (React.createElement("div", { className: css(classObject) },
        React.createElement("ul", { className: css(styles.detailContainer) },
            React.createElement("li", { className: css(styles.detail) },
                React.createElement("div", { className: css(styles.title) },
                    activityType === LifetimeActivityType.workoutCalorie && (React.createElement(React.Fragment, null,
                        React.createElement(FireIcon, { classObject: styles.icon, color: Colors.GrayDarkestB }),
                        "\u6D88\u8CBB\u30AB\u30ED\u30EA\u30FC")),
                    activityType === LifetimeActivityType.steps && (React.createElement(React.Fragment, null,
                        React.createElement(StepsIcon, { classObject: styles.icon, color: Colors.GrayDarkestB }),
                        "\u5408\u8A08\u6B69\u6570")),
                    activityType === LifetimeActivityType.mealCalorie && (React.createElement(React.Fragment, null,
                        React.createElement(FireIcon, { classObject: styles.icon, color: Colors.GrayDarkestB }),
                        "\u5E73\u5747\u6442\u53D6\u30AB\u30ED\u30EA\u30FC"))),
                React.createElement("div", { className: css(styles.detailValue) },
                    React.createElement("span", null, value ? "".concat(value.toLocaleString()).concat(unit) : '-'),
                    !!diff && (React.createElement("span", { className: css(styles.diff, diff >= 1 ? styles.diffPositive : styles.diffNegative) },
                        diff >= 1 && '+',
                        diff.toLocaleString(),
                        unit)))))));
};
var styles = StyleSheet.create({
    detailContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        margin: '16px auto 14px',
    },
    detail: {
        fontSize: 10,
        backgroundColor: Colors.BackgroundLightGray,
        width: '100%',
        height: 62,
        borderRadius: 2,
        padding: '10px 12px',
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 8,
    },
    icon: {
        marginRight: 4,
        width: 14,
        height: 15,
    },
    detailValue: {
        fontSize: 14,
        fontWeight: 'bold',
        color: Colors.Black,
    },
    diff: {
        fontSize: 10,
        fontWeight: 'bold',
        marginLeft: 4,
    },
    diffPositive: {
        color: Colors.Accent,
    },
    diffNegative: {
        color: Colors.PrimaryDark,
    },
});
