var _a, _b;
import * as React from 'react';
import { useState, useEffect } from 'react';
import { css, StyleSheet } from 'aphrodite';
import { Colors, MediaBreakPointUp } from '../styles';
import { DatePagination, DatePaginationStep } from '../molecules/date_pagination';
import { NutrientItems } from './nutrient_items';
import { PageLoading } from './page_loading';
import { ArrowDirection, Button, ButtonType, ArrowIconWithMargin } from '../atoms';
import { useNavigate } from 'react-router-dom';
import { DailyTasksPageTaskName, MealSubtaskPageName, routes } from '@lean-body/src/route';
import { getFormattedToFormat, useQuery } from '@lean-body/src/util';
import dayjs from 'dayjs';
import { TASK_DATE_QUERY } from '@lean-body/src/domain';
import { HintIcon } from '../atoms/icons/hint_icon';
import { TriangleIcon } from '../atoms/icons/triangle_icon';
import { MealBasicScoreBox } from '../molecules/meal_basic_score_box';
import { MealScoreBox } from '../molecules/meal_score_box';
import { MealDetail } from './meal_detail';
import { MealNutrientTabs } from '../pages/nutrient_page';
import { ZENDESK_NUTRIENTS_JUDGEMENT } from '../constants';
import { GrayBoxTabs, TabTypes } from '../molecules/gray_box_tabs';
import { LifetimeTaskActivity } from './lifetime_task_activity';
import { ActivityTabs, LifetimeActivityType } from './lifetime_activity_chart';
export var MEAL_EMPTY_MESSAGE = '3食分入力すると\n評価されます';
export var MealTask = function (_a) {
    var apiClient = _a.apiClient, containerClassObject = _a.containerClassObject, headerClassObject = _a.headerClassObject, arrowIconClassObject = _a.arrowIconClassObject, homeDay = _a.homeDay, onClickBackHome = _a.onClickBackHome;
    var navigate = useNavigate();
    var query = useQuery();
    var today = new Date();
    var displayNutrientKeys = ['calorie', 'protein', 'lipid', 'carbohydrate', 'salt_amount'];
    var _b = useState(dayjs(query.get(TASK_DATE_QUERY) || today).toDate()), date = _b[0], setDate = _b[1];
    var _c = useState(), score = _c[0], setScore = _c[1];
    var _d = useState([]), nutrients = _d[0], setNutrients = _d[1];
    var _e = useState(false), isEntered = _e[0], setIsEntered = _e[1];
    var _f = useState(), mealsForDay = _f[0], setMealsForDay = _f[1];
    var _g = useState(ActivityTabs.daily), tab = _g[0], setTab = _g[1];
    var _h = useState(DatePaginationStep.day), datePaginationStep = _h[0], setDatePaginationStep = _h[1];
    useEffect(function () {
        onChangeTab(tab);
    }, [tab]);
    useEffect(function () {
        if (tab === ActivityTabs.daily) {
            fetchMealDetail();
        }
    }, [date, tab]);
    var fetchMealDetail = function () {
        apiClient.fetchMealScores(date).then(function (fetchedScores) { return setScore(fetchedScores); });
        var allKey = Object.entries(MealNutrientTabs).find(function (_a) {
            var key = _a[0], value = _a[1];
            if (value === MealNutrientTabs.all)
                return key;
        })[0];
        apiClient.fetchMealNutrients(date, allKey).then(function (v) {
            setNutrients(v.nutrients.filter(function (v) { return displayNutrientKeys.includes(v.key); }));
            setIsEntered(v.isEntered);
        });
        apiClient.fetchMealsForDay(date).then(function (v) { return setMealsForDay(v); });
    };
    var onChangeTab = function (key) {
        if (key === ActivityTabs.weekly) {
            setDatePaginationStep(DatePaginationStep.week);
        }
        else if (key === ActivityTabs.monthly) {
            setDatePaginationStep(DatePaginationStep.month);
        }
        else if (key === ActivityTabs.quarterly) {
            setDatePaginationStep(DatePaginationStep.quarter);
        }
        else {
            setDatePaginationStep(DatePaginationStep.day);
        }
    };
    var onClickDatePagination = function (value) {
        setDate(dateMaxToday(value));
    };
    var dateMaxToday = function (date) {
        var todayjs = dayjs(today);
        if (todayjs.isBefore(date)) {
            return today;
        }
        else {
            return date;
        }
    };
    var onClickScoreDetail = function (scoreKey) {
        navigate("".concat(routes.MEAL_SCORE.compile(), "?date=").concat(getFormattedToFormat(date), "&type=").concat(scoreKey));
    };
    var onClickNutrientDetail = function () {
        navigate("".concat(routes.NUTRIENTS.compile(), "?date=").concat(getFormattedToFormat(date)));
    };
    var onClickBreakfast = function () {
        navigateDailySubtask(MealSubtaskPageName.breakfast);
    };
    var onClickLunch = function () {
        navigateDailySubtask(MealSubtaskPageName.lunch);
    };
    var onClickDinner = function () {
        navigateDailySubtask(MealSubtaskPageName.dinner);
    };
    var onClickSnacking = function () {
        navigateDailySubtask(MealSubtaskPageName.snacking);
    };
    var navigateDailySubtask = function (subtaskName) {
        var taskName = DailyTasksPageTaskName.meal;
        navigate("".concat(routes.DAILY_TASKS_SUBTASK.compile({ taskName: taskName, subtaskName: subtaskName }), "?date=").concat(getFormattedToFormat(date)));
    };
    if (!score) {
        return React.createElement(PageLoading, null);
    }
    return (React.createElement("div", { className: css(containerClassObject) },
        React.createElement(ArrowIconWithMargin, { classObject: arrowIconClassObject, direction: ArrowDirection.left, color: Colors.TextDark, onClick: function () {
                onClickBackHome(homeDay);
            } }),
        React.createElement("div", { className: css(headerClassObject) }, "\u98DF\u4E8B\u8A18\u9332"),
        React.createElement(GrayBoxTabs, { setTab: setTab, tab: tab, tabType: TabTypes.activity, classObject: styles.tabs }),
        React.createElement(DatePagination, { value: date, max: today, step: datePaginationStep, onClickNext: onClickDatePagination, onClickPrev: onClickDatePagination, classObject: styles.datePagination }),
        tab === ActivityTabs.daily ? (React.createElement(React.Fragment, null,
            React.createElement("div", { className: css(styles.sectionTitle) },
                "\u98DF\u4E8B\u30B9\u30B3\u30A2",
                React.createElement("a", { href: ZENDESK_NUTRIENTS_JUDGEMENT, className: css(styles.hintLink), target: "_blank" },
                    React.createElement(HintIcon, { color: Colors.Gray, classObject: styles.hintIcon })),
                !score.enteredThreeMeals && (React.createElement("div", { className: css(styles.commentBox) },
                    React.createElement(TriangleIcon, { classObject: styles.triangleIcon }),
                    React.createElement("div", { className: css(styles.commentText) }, MEAL_EMPTY_MESSAGE.replace(/\n/g, ''))))),
            React.createElement("div", { className: css(styles.scoreSection) },
                React.createElement("div", { className: css(styles.basicScoreBox) },
                    React.createElement(MealBasicScoreBox, { scoreDetail: score.basic, onClickDetail: onClickScoreDetail })),
                React.createElement("div", { className: css(styles.scoreBox) },
                    React.createElement(MealScoreBox, { scoreDetail: score.diet, onClickDetail: onClickScoreDetail }),
                    React.createElement(MealScoreBox, { scoreDetail: score.vitality, onClickDetail: onClickScoreDetail }),
                    React.createElement(MealScoreBox, { scoreDetail: score.intestinalCleanliness, onClickDetail: onClickScoreDetail }),
                    React.createElement(MealScoreBox, { scoreDetail: score.skinGlossiness, onClickDetail: onClickScoreDetail }))),
            React.createElement("div", { className: css(styles.delimiterBox) },
                React.createElement("div", { className: css(styles.delimiter) })),
            React.createElement("div", { className: css(styles.sectionTitle) }, "\u6804\u990A\u30D0\u30E9\u30F3\u30B9"),
            React.createElement(NutrientItems, { nutrients: nutrients, isEntered: isEntered }),
            React.createElement(Button, { buttonType: ButtonType.borderGray, className: css(styles.nutientButton), onClick: onClickNutrientDetail }, "\u3059\u3079\u3066\u898B\u308B"),
            React.createElement("div", { className: css(styles.delimiterBox) },
                React.createElement("div", { className: css(styles.delimiter) })),
            React.createElement(MealDetail, { title: "\u671D\u98DF", meal: mealsForDay === null || mealsForDay === void 0 ? void 0 : mealsForDay.breakfast, onClick: onClickBreakfast }),
            React.createElement("div", { className: css(styles.delimiterBox) },
                React.createElement("div", { className: css(styles.delimiter) })),
            React.createElement(MealDetail, { title: "\u663C\u98DF", meal: mealsForDay === null || mealsForDay === void 0 ? void 0 : mealsForDay.lunch, onClick: onClickLunch }),
            React.createElement("div", { className: css(styles.delimiterBox) },
                React.createElement("div", { className: css(styles.delimiter) })),
            React.createElement(MealDetail, { title: "\u5915\u98DF", meal: mealsForDay === null || mealsForDay === void 0 ? void 0 : mealsForDay.dinner, onClick: onClickDinner }),
            React.createElement("div", { className: css(styles.delimiterBox) },
                React.createElement("div", { className: css(styles.delimiter) })),
            React.createElement(MealDetail, { title: "\u9593\u98DF", meal: mealsForDay === null || mealsForDay === void 0 ? void 0 : mealsForDay.snacking, onClick: onClickSnacking }))) : (React.createElement(LifetimeTaskActivity, { activityType: LifetimeActivityType.mealCalorie, apiClient: apiClient, tab: tab, goalDate: date, classObject: styles.activity }))));
};
var styles = StyleSheet.create({
    detailContainer: (_a = {
            marginTop: 0
        },
        _a[MediaBreakPointUp.MD] = {
            paddingTop: 0,
        },
        _a),
    tabs: {
        marginBottom: 20,
    },
    datePagination: {
        marginBottom: 14,
    },
    sectionTitle: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        fontSize: 16,
        fontWeight: 'bold',
        lineHeight: '160%',
        letterSpacing: 1.28,
        paddingTop: 24,
        paddingBottom: 8,
    },
    hintLink: {
        height: 22,
        marginLeft: 4,
    },
    hintIcon: {
        width: 22,
        height: 22,
    },
    commentBox: {
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        right: 0,
    },
    triangleIcon: {
        position: 'absolute',
        left: -8,
    },
    commentText: {
        backgroundColor: Colors.BackgroundBlue,
        color: Colors.Black,
        fontSize: 11,
        fontWeight: 500,
        lineHeight: '100%',
        letterSpacing: 0.44,
        padding: '7px 10px',
        borderRadius: 4,
    },
    scoreSection: {
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
    },
    basicScoreBox: {
        padding: ' 0 12px 0 20px',
        borderRadius: 14,
        border: "1.5px solid ".concat(Colors.BorderLightGray),
    },
    scoreBox: {
        display: 'flex',
        gap: 8,
    },
    delimiterBox: {
        width: '100%',
        height: 8,
        marginTop: 24,
    },
    delimiter: (_b = {
            backgroundColor: Colors.CyanLightest,
            width: '100%',
            height: 8,
            position: 'absolute',
            left: 0
        },
        _b[MediaBreakPointUp.MD] = {
            position: 'unset',
        },
        _b),
    nutientButton: {
        color: Colors.Black,
        fontSize: 12,
        fontWeight: 'bold',
        width: '100%',
        height: 33,
        marginTop: 24,
        marginBottom: 8,
        padding: '10px 10px 11px',
    },
    plusIcon: {
        width: 13,
        height: 13,
        marginRight: 6,
    },
    activity: {
        width: 327,
        margin: '0 auto',
    },
});
