var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a, _b;
import * as React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { LocalStorage } from '@lean-body/src/infra';
import { getFormattedToFormat, getFormattedToMonthDateDay, useNavigateBack, useSmMediaQuery } from '@lean-body/src/util';
import { Button, ButtonType } from '../atoms';
import { MediaBreakPointUp, Sizes } from '../styles';
import { MealLabel } from '@lean-body/src/domain';
import { Dialog } from '../molecules';
import { RecordMealMenuDetail } from './record_meal_menu_detail';
import { ExitConfirmationDialog } from '../molecules/exit_confirmation_dialog';
import { RecordMealSelectForm } from './record_meal_select_form';
import { RecordMealConfirmForm } from './record_meal_confirm_form';
import { RecordMealAnalysis } from './record_meal_analysis';
import { LoadingOverlay } from '../atoms/loading_overlay';
export var RecordMealActionType = {
    select: 'select',
    analysis: 'analysis',
    confirm: 'confirm',
};
export var MENU_AMOUNT_PERCENT_FULL = 100;
var MealDefaultTime = {
    breakfast: '07:00',
    lunch: '12:00',
    dinner: '19:00',
    snacking: '15:00',
};
export var RecordMeal = function (_a) {
    var date = _a.date, name = _a.name, mealTask = _a.mealTask, classObject = _a.classObject, apiClient = _a.apiClient, config = _a.config;
    var localStorage = new LocalStorage();
    var navigateBack = useNavigateBack();
    var isSM = useSmMediaQuery();
    var mealType = name;
    var headerTitle = "".concat(getFormattedToMonthDateDay(date), " ").concat(MealLabel[mealType]);
    var _b = React.useState(RecordMealActionType.select), action = _b[0], setAction = _b[1];
    var _c = React.useState(false), isChangedMenu = _c[0], setIsChangedMenu = _c[1];
    var _d = React.useState(new Map()), selectedMenuMap = _d[0], setSelectedMenuMap = _d[1];
    var _e = React.useState(null), menuSelection = _e[0], setMenuSelection = _e[1];
    var _f = React.useState(), menuAmount = _f[0], setMenuAmount = _f[1];
    var _g = React.useState(), detailMenuIndex = _g[0], setDetailMenuIndex = _g[1];
    var _h = React.useState(false), showExitDialog = _h[0], setShowExitDialog = _h[1];
    var _j = React.useState(MealDefaultTime[mealType]), time = _j[0], setTime = _j[1];
    var _k = React.useState(''), errorMessage = _k[0], setErrorMessage = _k[1];
    var _l = React.useState(false), showErrorDialog = _l[0], setShowErrorDialog = _l[1];
    var _m = React.useState(new Map()), imageMap = _m[0], setImageMap = _m[1];
    var _o = React.useState(), analysisImage = _o[0], setAnalysisImage = _o[1];
    var _p = React.useState(new Map()), selectedAnalysisMenuMap = _p[0], setSelectedAnalysisMenuMap = _p[1];
    var _q = React.useState(false), isDisabledSaveButton = _q[0], setIsDisabledSaveButton = _q[1];
    var _r = React.useState(false), isSaving = _r[0], setIsSaving = _r[1];
    React.useEffect(function () {
        // 以前保存した内容をselectedMenusとimageMapに保管する
        apiClient.getMeal(getFormattedToFormat(date), mealType).then(function (userMeal) {
            var menuSelectionMap = new Map(userMeal === null || userMeal === void 0 ? void 0 : userMeal.userMealMenus.map(function (menu) { return [menu.menuKeyForConfirmPage, menu]; }));
            setSelectedMenuMap(menuSelectionMap);
            var initialImageMap = new Map();
            var files = userMeal === null || userMeal === void 0 ? void 0 : userMeal.userMealImages.map(function (image, i) { return image.fileMap(i); });
            if (files === null || files === void 0 ? void 0 : files.length) {
                Promise.all(files).then(function (fileList) {
                    fileList.forEach(function (v) {
                        initialImageMap.set(v.index, { id: v.id, url: v.url, file: v.file });
                    });
                    setImageMap(initialImageMap);
                });
            }
            if (userMeal && !userMeal.isNotEating) {
                setAction(RecordMealActionType.confirm);
            }
        });
    }, []);
    var onCloseMenuDetail = function () {
        setMenuSelection(null);
    };
    var onClickBack = function () {
        if (isChangedMenu) {
            setShowExitDialog(true);
        }
        else {
            navigateBack();
        }
    };
    var onClickExit = function () {
        navigateBack();
    };
    var onCloseExitDialog = function () {
        setShowExitDialog(false);
    };
    var onClickMenu = function (index, menu) {
        var _a, _b;
        var detailMenu;
        setDetailMenuIndex(index);
        if (index != null) {
            // index で数値を受け取っている場合は画像解析で選択されたメニュー
            if (Number.isInteger(index)) {
                detailMenu = (_a = selectedAnalysisMenuMap.get(Number(index))) !== null && _a !== void 0 ? _a : menu;
            }
            else {
                detailMenu = (_b = selectedMenuMap.get(index.toString())) !== null && _b !== void 0 ? _b : menu;
            }
        }
        else {
            detailMenu = menu;
        }
        setMenuSelection(detailMenu);
        setMenuAmount(detailMenu.amountPercentage.toString());
    };
    var onClickSelectMenu = function (menu) {
        setIsChangedMenu(true);
        menu.amount = MENU_AMOUNT_PERCENT_FULL / 100;
        setSelectedMenuMap(new Map(selectedMenuMap.set(menu.menuKey, menu)));
    };
    var checkMenuExists = function () {
        if (action === RecordMealActionType.confirm && isNoInputData()) {
            setAction(RecordMealActionType.select);
        }
    };
    var onClickDeselectMenu = function (menuKey) {
        setIsChangedMenu(true);
        selectedMenuMap.delete(menuKey);
        setSelectedMenuMap(new Map(selectedMenuMap));
        checkMenuExists();
    };
    var onClickDeleteAnalysisMenu = function (index) {
        selectedAnalysisMenuMap.delete(index);
        setSelectedAnalysisMenuMap(new Map(selectedAnalysisMenuMap));
        setIsChangedMenu(true);
        checkMenuExists();
    };
    var onAddImageMap = function (file, url) {
        var index = imageMap.size ? Math.max.apply(Math, Array.from(imageMap.keys())) + 1 : 0;
        setImageMap(new Map(imageMap.set(index, { id: null, url: url, file: file })));
    };
    var onDeleteImage = function (index) {
        imageMap.delete(index);
        setImageMap(new Map(imageMap));
        checkMenuExists();
    };
    var onAnalyseError = function (message) {
        onShowError(message);
        setAction(RecordMealActionType.select);
    };
    var onShowError = function (message) {
        setErrorMessage(message);
        setShowErrorDialog(true);
    };
    var onClickSaveAmount = function () {
        var amount = menuAmount ? parseFloat(menuAmount) : MENU_AMOUNT_PERCENT_FULL;
        if (amount <= 0) {
            setErrorMessage('0より大きい数値を入力してください。');
            setShowErrorDialog(true);
            return;
        }
        menuSelection.amount = amount / 100;
        if (detailMenuIndex == null) {
            setSelectedMenuMap(new Map(selectedMenuMap.set(menuSelection.menuKey, menuSelection)));
        }
        else {
            if (Number.isInteger(detailMenuIndex)) {
                setSelectedAnalysisMenuMap(new Map(selectedAnalysisMenuMap.set(Number(detailMenuIndex), menuSelection)));
            }
            else {
                setSelectedMenuMap(new Map(selectedMenuMap.set(detailMenuIndex.toString(), menuSelection)));
            }
        }
        setMenuSelection(null);
        setIsChangedMenu(true);
    };
    var onClickSkipMeal = function () { return __awaiter(void 0, void 0, void 0, function () {
        var isFirstMeal;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, apiClient.skipMeal(mealType, getFormattedToFormat(date))];
                case 1:
                    _a.sent();
                    isFirstMeal = mealTask.eatenMealsCount === 0;
                    if (isFirstMeal) {
                        localStorage.saveShowMealTaskNotificationPermissionDialog(true);
                    }
                    navigateBack();
                    return [2 /*return*/];
            }
        });
    }); };
    var onSaveMeal = function () { return __awaiter(void 0, void 0, void 0, function () {
        var menus, keepImageIDs, files, isSaveError, isFirstMeal;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (isNoInputData())
                        return [2 /*return*/];
                    setIsDisabledSaveButton(true);
                    setIsSaving(true);
                    menus = Array.from(selectedAnalysisMenuMap.values());
                    Array.from(selectedMenuMap.values()).map(function (menu) { return menus.push(menu); });
                    keepImageIDs = [];
                    files = [];
                    Array.from(imageMap.values()).forEach(function (v) {
                        if (v.id == null) {
                            files.push(v.file);
                        }
                        else {
                            keepImageIDs.push(v.id);
                        }
                    });
                    isSaveError = false;
                    return [4 /*yield*/, apiClient.saveMeal(getFormattedToFormat(date), time, mealType, keepImageIDs, menus, files).catch(function () {
                            setIsSaving(false);
                            setIsDisabledSaveButton(false);
                            isSaveError = true;
                        })];
                case 1:
                    _a.sent();
                    if (isSaveError)
                        return [2 /*return*/];
                    isFirstMeal = mealTask.eatenMealsCount === 0;
                    if (isFirstMeal) {
                        localStorage.saveShowMealTaskNotificationPermissionDialog(true);
                    }
                    // 食事を記録したことあるか保存する
                    localStorage.saveHasRecordedMeal(true);
                    navigateBack();
                    return [2 /*return*/];
            }
        });
    }); };
    var isNoInputData = function () {
        return !selectedMenuMap.size && !selectedAnalysisMenuMap.size && !imageMap.size;
    };
    var onCloseErrorDialog = function () {
        setShowErrorDialog(false);
    };
    return (React.createElement(React.Fragment, null,
        menuSelection && isSM ? (React.createElement(RecordMealMenuDetail, { menu: menuSelection, amount: menuAmount, onAmountInputChange: setMenuAmount, onCloseMenuDetail: onCloseMenuDetail, onClickSaveAmount: onClickSaveAmount })) : (React.createElement(React.Fragment, null,
            React.createElement("div", { className: css(classObject, styles.container) },
                action === RecordMealActionType.select ? (React.createElement(RecordMealSelectForm, { headerTitle: headerTitle, selectedMenuMap: selectedMenuMap, selectedAnalysisMenuMap: selectedAnalysisMenuMap, imageMap: imageMap, mealType: mealType, isNoInputData: isNoInputData(), setAction: setAction, setAnalysisImage: setAnalysisImage, onClickSelectMenu: onClickSelectMenu, onClickDeselectMenu: onClickDeselectMenu, onClickMenuDetail: onClickMenu, onClickBack: onClickBack, onClickSkipMeal: onClickSkipMeal, classObject: styles.contents, apiClient: apiClient, config: config })) : action === RecordMealActionType.analysis ? (React.createElement(RecordMealAnalysis, { headerTitle: headerTitle, selectedMenuMap: selectedMenuMap, selectedAnalysisMenuMap: selectedAnalysisMenuMap, analysisImage: analysisImage, mealType: mealType, setAction: setAction, setIsChangedMenu: setIsChangedMenu, setSelectedAnalysisMenuMap: setSelectedAnalysisMenuMap, onAddImageMap: onAddImageMap, onAnalyseError: onAnalyseError, onShowError: onShowError, classObject: styles.contents, apiClient: apiClient, config: config })) : (React.createElement(RecordMealConfirmForm, { headerTitle: headerTitle, selectedMenuMap: selectedMenuMap, selectedAnalysisMenuMap: selectedAnalysisMenuMap, imageMap: imageMap, time: time, setAction: setAction, onTimeChange: setTime, onClickBack: onClickBack, onClickMenu: onClickMenu, onClickDeleteMenu: onClickDeselectMenu, onClickDeleteAnalysisMenu: onClickDeleteAnalysisMenu, onDeleteImage: onDeleteImage, onSaveMeal: onSaveMeal, isDisabledSaveButton: isDisabledSaveButton, classObject: styles.contents })),
                showExitDialog && (React.createElement(ExitConfirmationDialog, { description: "\u7DE8\u96C6\u4E2D\u306E\u767B\u9332\u5185\u5BB9\u306F\u7834\u68C4\u3055\u308C\u307E\u3059\u3002\u3088\u308D\u3057\u3044\u3067\u3059\u304B\uFF1F", exitButtonMessage: "\u7834\u68C4\u3057\u3066\u7D42\u4E86", cancelButtonMessage: "\u767B\u9332\u3092\u7D9A\u3051\u308B", onClickExit: onClickExit, onClickCancel: onCloseExitDialog }))),
            menuSelection && (React.createElement(RecordMealMenuDetail, { menu: menuSelection, amount: menuAmount, onAmountInputChange: setMenuAmount, onCloseMenuDetail: onCloseMenuDetail, onClickSaveAmount: onClickSaveAmount })))),
        isSaving && React.createElement(LoadingOverlay, { text: "\u767B\u9332\u4E2D\u2026" }),
        showErrorDialog && (React.createElement(Dialog, { classObject: styles.errorDialog, onClickCancel: onCloseErrorDialog, content: React.createElement(React.Fragment, null,
                React.createElement("div", { className: css(styles.errorMessage) }, errorMessage),
                React.createElement(Button, { className: css(styles.errorDialogCloseButton), buttonType: ButtonType.primary, onClick: onCloseErrorDialog }, "OK")) }))));
};
var styles = StyleSheet.create({
    container: (_a = {
            height: "calc(100vh - ".concat(Sizes.MobileHeaderHeight, "px)"),
            display: 'flex',
            flexDirection: 'column'
        },
        _a[MediaBreakPointUp.MD] = {
            height: '100vh',
        },
        _a),
    contents: (_b = {
            flex: 1,
            padding: '0 24px 30px'
        },
        _b[MediaBreakPointUp.MD] = {
            padding: 0,
            width: 600,
            margin: '0 auto 50px',
        },
        _b),
    errorDialog: {
        width: 327,
        padding: '25px 24px 20px',
        borderRadius: 14,
    },
    errorMessage: {
        fontSize: 14,
        letterSpacing: 0.56,
        marginBottom: 20,
        textAlign: 'center',
    },
    errorDialogCloseButton: {
        width: '100%',
        height: 48,
        borderRadius: 24,
        padding: 0,
    },
});
